var generic = generic || {};
var site = site || {};

(function ($) {
  site.gdpr = {
    gdprRemInfoMessageBox: function () {
      if ($('.info-msg-gdpr').get(0)) {
        $('.info-msg-gdpr').remove();
      }
    },

    gdprSetInfoBoxPosition: function (infoLink, infoMessageBox, infoMsgArrow, isMobile) {
      var infoLinkTop = infoLink.offset().top + infoLink.height() + infoMsgArrow.outerHeight(true);
      var infoLinkLeft = infoLink.offset().left;
      var infoMsgMaxWidth = parseInt(infoMessageBox.css('max-width').replace('px', ''), 10);
      var infoMsgArrowMidPoint = infoMsgArrow.outerWidth(true) / 2;
      var popupLeftPosMobile = 10;
      var infoWidth = infoLinkLeft + (infoMsgMaxWidth + 25);
      var infoMessageBoxOuterWidth = infoMessageBox.outerWidth(true);

      if (isMobile) {
        infoMsgArrow.css({
          left: (infoLinkLeft - infoMsgArrowMidPoint) + 'px'
        });
        infoLinkLeft = popupLeftPosMobile;
      } else if ((infoMessageBoxOuterWidth === infoMsgMaxWidth) && (infoWidth > screen.width)) {
        infoLinkLeft = infoLinkLeft - infoMessageBox.innerWidth() + infoMsgArrowMidPoint;
        infoMsgArrow.addClass('top-right-arrow');
      }
      infoMessageBox.css({
        top: infoLinkTop + 'px',
        left: infoLinkLeft + 'px'
      });
    },

    gdprToolTip: function (context) {
      var isMobile;
      var infoLink;
      var infoBoxEle;
      var infoMessageBox;
      var infoMsgArrow;

      if (Drupal.settings.globals_variables.gdpr_compliant) {
        isMobile = Unison.fetch.now().name === 'small';

        $('body', context).click(function (e) {
          if (e.target.className !== 'info-msg-gdpr' && e.target.className !== 'info-msg-gdpr__txt') {
            $('.info-msg-gdpr').addClass('hidden');
          }
        });
        $('.info-link-gdpr', context).live('click', function (e) {
          e.preventDefault();
          infoLink = $(e.currentTarget);

          site.gdpr.gdprRemInfoMessageBox();

          $("<div class='info-msg-gdpr'><div class='info-msg-gdpr__arrow'></div><div class='info-msg-gdpr__txt'></div></div>").appendTo('body');
          infoBoxEle = $('#' + infoLink.attr('message-in'));
          infoMessageBox = $('.info-msg-gdpr');
          infoMsgArrow = $('.info-msg-gdpr__arrow');

          $('.info-msg-gdpr__txt').html("<div class='info-msg-gdpr__close'>X</div>" + infoBoxEle.html());

          $('#cboxClose, .info-msg-gdpr__close', context).live('click', function (eve) {
            eve.stopPropagation();
            site.gdpr.gdprRemInfoMessageBox();
          });

          site.gdpr.gdprSetInfoBoxPosition(infoLink, infoMessageBox, infoMsgArrow, isMobile);
          $(window).on('resize-debounced', site.gdpr.gdprSetInfoBoxPosition(infoLink, infoMessageBox, infoMsgArrow, isMobile));

          $('.info-msg-gdpr__txt span').live('mousedown', function () {
            window.location = $(this).attr('data-url');
          });
        });
      }
    }

  };

  Drupal.behaviors.gdpr = {
    attach: function (context) {
      site.gdpr.gdprToolTip(context);
    }
  };
})(jQuery);
